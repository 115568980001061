import { ref as valtioRef } from "valtio";

import { IS_NODE } from "@/__main__/constants.mjs";
import featureFlags, { allowRead } from "@/__main__/feature-flags.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import appRefs from "@/app/refs.mjs";
import {
  destroyAdHooks,
  videoAdsMap,
} from "@/feature-ads/constants/constants.mjs";
import {
  createPlaceholder,
  destroyAd,
  onResize,
  startObserving as startObservingVideo,
  stopObserving as stopObservingVideo,
} from "@/feature-ads/global-video-ad.mjs";
import { setupPostmatchPlaceholder } from "@/feature-ads/postmatch-placeholder.mjs";
import { setupProfilePlaceholder } from "@/feature-ads/profile-placeholder.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import matchRefs from "@/shared/Match.refs.jsx";
import overlayRefs from "@/shared/OverlayContainerWithAd.refs.jsx";
import profileRefs from "@/shared/Profile.refs.jsx";
import { appLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  matchRefs,
  profileRefs,
});

const cleanup = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destroyAdHooks: null as Map<object, Record<string, any>> | null,
  destroyAd: null as ((name: string) => void) | null,
};

let has_setup = false;

const enableVideoAds = () => {
  if (has_setup) return;
  has_setup = true;
  appLog(`[video-ads] setting up ${videoAdsMap.size} video ads`);
  // We need to start monitoring changes in DOM nodes before setting the ref,
  // because video-ad-xxx will be created on the page after the ref is set.
  // If there is no observer at this time, the addition of video-ad-xxx cannot be monitored,
  // and the advertising script cannot be inserted into it.
  startObservingVideo();
  videoAdsMap.forEach((data, ref) => {
    // special case: in-game ads must be disabled if adsRefs.showIngameVideo is
    // false
    if (!adsRefs.showIngameVideo && data.pageMacro === "in-game") return;

    const placeholder = createPlaceholder(data);
    if (!placeholder) return;
    ref[data._refKey || "videoAd"] = valtioRef(placeholder);
  });

  original.set({
    matchRefs: {
      setupPlaceholder: setupPostmatchPlaceholder,
    },
  });

  original.set({
    profileRefs: {
      setupPlaceholder: setupProfilePlaceholder,
    },
  });

  destroyAdHooks.forEach(([obj, key]) => {
    const ogmap = cleanup.destroyAdHooks?.get(obj) || {};

    (cleanup.destroyAdHooks ??= new Map()).set(
      obj,
      Object.assign(ogmap, {
        [key]: obj[key],
      }),
    );

    obj[key] = () => destroyAd(key);
  });

  cleanup.destroyAd = overlayRefs.destroyAd;
  overlayRefs.destroyAd = destroyAd;

  if (!IS_NODE) globals.addEventListener?.("resize", onResize);
};

export function setup() {
  appRefs.fetchedFeatureFlags.then(() => {
    allowRead();
    if (!featureFlags.adsVideoNew && featureFlags.ads) enableVideoAds();
    eventBus.on("adsStatusChange", (enable) => {
      if (enable) {
        enableVideoAds();
      } else {
        setFeatureFlag("adsVideo", false);
      }
    });
  });
}

export function teardown() {
  if (!has_setup) return;
  has_setup = false;

  destroyAd("feature teardown");

  videoAdsMap.forEach((data, ref) => {
    ref[data._refKey || "videoAd"] = null;
  });

  destroyAdHooks.forEach(([obj, key]) => {
    const original = cleanup.destroyAdHooks.get(obj);
    obj[key] = original[key];
  });

  overlayRefs.destroyAd = cleanup.destroyAd;

  if (!IS_NODE) globals.removeEventListener("resize", onResize);

  stopObservingVideo();
}
